import { IcEmail, IcFacebook, IcInstagram, IcMap, IcTwitter, IcWhatsapps } from "../../../../assets";

const Footer = () => {
    // const mitra_lsp = [
    //     {
    //         title: 'LPS MIGAS',
    //     },
    //     {
    //         title: 'LSP Manajemen Resiko',
    //     },
    //     {
    //         title: 'LSP STP Bandung',
    //     },
    //     {
    //         title: 'LSP Kimia Industri',
    //     },
    //     {
    //         title: 'Indonesia Kompeten',
    //     },
    //     {
    //         title: 'BNSP',
    //     },
    //     {
    //         title: 'KOMINFO',
    //     },
    //     {
    //         title: 'KEMNAKER Bandung',
    //     },
    //     {
    //         title: 'LSP Teknologi',
    //     },
    //     {
    //         title: 'LPK Alfabank',
    //     },
    //     {
    //         title: 'Islamic Boarding School',
    //     },
    // ];
    const link_populer = [
        {
            title: 'Tentang Kami',
        },
        {
            title: 'Visi & Misi',
        },
        {
            title: 'Struktur Organisasi',
        },
        {
            title: 'Stake Holder',
        },
        {
            title: 'Komitmen Ketidak Berpihakan',
        },
        {
            title: 'Rencana Strategis',
        },
        {
            title: 'Program Kerja',
        },
        {
            title: 'Mitra Kerja',
        },
    ];
    const kontak = [
        {
            icon: <IcMap />,
            title: ('Perum Razbi Asri Blok. D No. 4 Rt. 018 Rw. 003 Mustikajaya, Mustikajaya, Kota Bekasi, Jawabarat').toUpperCase(),
            on_click: () => { },
        },
        {
            icon: <IcEmail />,
            title: 'mkbilsp@gmail.com',
            on_click: () => { },
        },
        {
            icon: <IcInstagram />,
            title: '@mkbi.official',
            on_click: () => { window.open('https://instagram.com/mkbi.official?igshid=OGQ5ZDc2ODk2ZA==') },
        },
        {
            icon: <IcFacebook />,
            title: 'Lsp Mkbi',
            on_click: () => { window.open('https://www.facebook.com/profile.php?id=61550340341592&mibextid=ZbWKwL') },
        },
        {
            icon: <IcTwitter />,
            title: 'Lsp Mkbi',
            on_click: () => { window.open('https://twitter.com/LspMKBI') },
        },
        {
            icon: <IcWhatsapps />,
            title: '0811-8280-133',
            on_click: () => { window.open('https://wa.me/628118280133') },
        }
    ];

    return (
        <footer className="bg-slate-900 flex justify-center text-white">
            <div className="w-full max-w-[1140px] px-3">
                <div className="flex flex-wrap flex-auto gap-10 py-10">
                    {/* <div>
                        <div className="border-b inline-block pb-2 border-cyan-500">
                            <span className="text-cyan-500 text-lg">Mitra LSP</span>
                        </div>
                        <div className="mt-2">
                            {mitra_lsp.map((item, index) => {
                                return (
                                    <div key={index} className="py-2 font-normal text-slate-400">{item.title}</div>
                                );
                            })}
                        </div>
                    </div> */}
                    <div>
                        <div className="border-b inline-block pb-2 border-cyan-500">
                            <span className="text-cyan-500 text-lg">Link Populer</span>
                        </div>
                        <div className="mt-2">
                            {link_populer.map((item, index) => {
                                return (
                                    <div key={index} className="py-2 font-normal text-slate-400">{item.title}</div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="flex-1">
                        <div className="border-b inline-block pb-2 border-cyan-500">
                            <span className="text-cyan-500 text-lg">Kontak</span>
                        </div>
                        <div className="mt-2">
                            {kontak.map((item, index) => {
                                return (
                                    <div key={index} className="py-2 font-normal text-slate-400 flex gap-2 cursor-pointer" onClick={() => item.on_click && item.on_click()}>
                                        <span>{item.icon}</span>
                                        {item.title}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="flex justify-center flex-1">
                        <div className="flex flex-col gap-1">
                            <div className="bg-white aspect-square max-w-[20rem] rounded flex justify-center items-center">
                                <div className="flex flex-col gap-2">
                                    <div className="flex justify-center">
                                        <span className="text-slate-400"><IcInstagram width="80" height="80" /></span>
                                    </div>
                                    <span className="text-center text-blue-500 text-sm cursor-pointer" onClick={() => kontak[2].on_click()}>View this post on Instagram</span>
                                </div>
                            </div>
                            <div className="bg-slate-600 h-28 max-w-[20rem] rounded"></div>
                            <small className="text-center">A post shared by LSP Teknologi Digital (@lsp.td)</small>
                        </div>
                    </div>
                </div>
                <hr className="border-slate-400" />
                <div className="py-3">
                    <small className="text-slate-400">© Copyright 2023 - Indonesia Kompeten</small>
                </div>
            </div>
        </footer>
    );
}

export default Footer;